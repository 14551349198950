<template>
  <v-autocomplete
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
    color="tertiary"
    :label="$t('global.expense_management')"
    item-value="id"
    item-text="name"
    :menu-props="{ offsetY: true, overflowY: true }"
    :loading="loading || fetching"
    :items="items"
    :item-disabled="itemDisabled"
    :clearable="clearable"
    @input="$emit('input', $event)"
  >
    <template #item="{ item }">
      <v-list-item-avatar size="28" color="primary">
        <span class="white--text body-1 font-weight-medium">
          {{ item.name.substring(0, 2).toUpperCase() }}
        </span>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ $helpers.get(item, "project.name") || "-" }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <request-status-label :status="item.requestStatus" />
      </v-list-item-action>
    </template>
  </v-autocomplete>
</template>
<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-08-10 13:55:03
   * @modify date 2021-08-10 13:55:03
   * @desc ExpenseManagementPicker
   */
  import gql from "graphql-tag";

  export default {
    name: "ExpenseManagementPicker",
    components: {
      RequestStatusLabel: () => import("@/components/global/request-status-label")
    },
    inheritAttrs: false,
    props: {
      value: String,
      loading: Boolean,
      employee: String,
      clearable: {
        type: Boolean,
        default: true
      }
    },
    data: vm => ({
      fetching: false,
      items: []
    }),
    watch: {
      employee: {
        handler(employee) {
          if (employee) {
            this.fetchItems();
          }
        },
        immediate: true
      }
    },
    methods: {
      itemDisabled(item) {
        return item.requestStatus === "CLOSED" || item.requestStatus === "CANCELLED";
      },
      fetchItems() {
        const options = {
          query: gql`
            query filterExpenseManagements($criteria: ExpenseManagementFilterCriteria!) {
              filterExpenseManagements(criteria: $criteria) {
                id
                name
                requestStatus
                project {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            criteria: {
              employee: this.employee
            }
          },
          fetchPolicy: "no-cache"
        };
        this.fetching = true;
        this.$apollo
          .query(options)
          .then(({ data: { filterExpenseManagements, error }, errors }) => {
            if (!error && !errors) {
              this.items = filterExpenseManagements;
            }
          })
          .catch(console.error)
          .finally(() => (this.fetching = false));
      }
    }
  };
</script>
